import React from "react"
import { Form, Row } from 'antd';
import { AppButton, AppFormItem, AppInput, AppSelect } from ".";
import { FilterIcon } from "./Icons";

const DefaultFilterForm = ({initialValues, onFilter, statusOptions, ...rest}) => 
{
    const [form] = Form.useForm();
    const onFilterHandler = () => form
        .validateFields()
        .then((values) => {
            onFilter(values);
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });
    
    
    return (
        <Form 
            form={form}
            layout="vertical"
            name="form_default_filter"
            initialValues={ initialValues ? { ...initialValues } : { search : '', status: 1 }}
            {...rest}>
                <Row>
                    <AppFormItem span={18} name="search" label="Nome">
                        <AppInput placeholder="Informe o nome da categoria"/>
                    </AppFormItem>                        
                    <AppFormItem
                        span={6}
                        name="status"
                        label="Status" 
                        >
                        <AppSelect size="small" options={ 
                            statusOptions ? 
                            statusOptions :
                            [ { value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }]}></AppSelect>
                    </AppFormItem>   
                </Row>

                <AppButton onClick={onFilterHandler}><FilterIcon/> Filtrar</AppButton>
        </Form>
    )
}

export default DefaultFilterForm;