import React, { useState } from "react"
import { Modal, Form, message, Row, DatePicker } from 'antd';
import { CancelIcon, SaveIcon } from "../../../components/Icons";
import { PPCEvaluationService } from "../../../services";
import { ColaboratorSelect } from "../../colaborator/components";
import { ModelPPCSelect } from "../../model-ppc/components";
import { MonthAndYearHelper } from "../../../helpers";
import { AppFormItem } from "../../../components";

const PpcGenerateFormModal = ({ onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [colaboratorName, setColaboratorName ] = useState('');
    const [modelName, setModelName ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                const toMonthAndYear = MonthAndYearHelper.dateToMonthAndyear(values.date);

                await PPCEvaluationService.monthGenerate(values.colaboratorId, {
                    modelPPCId : values.modelPPCId,
                    monthAndYear : toMonthAndYear
                });
                
                message.success('Operação realizada com sucesso'); 
                onAfterOperation({ 
                    colaboratorId : values.colaboratorId, 
                    colaboratorName : colaboratorName,
                    modelPPCId : values.modelPPCId, 
                    modelPPCName : modelName,
                    monthAndYear: toMonthAndYear});  
                form.resetFields();
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }
            finally{
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de PPC" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: {loading}
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_generate_ppc"
                    initialValues={{ monthAndYear : '', modelPPCId: null, colaboratorId : null }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={8}
                            name="date"
                            label="Mês Ano" 
                            rules={[{ required: true, message: "Campo obrigatório" }]}
                            >
                            <DatePicker size="small" format="MM-YYYY" picker="month"/>
                        </AppFormItem>
                        <AppFormItem
                            span={16}
                            name="colaboratorId"
                            label="Colaborador" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <ColaboratorSelect 
                                onSelect={(e, op) => setColaboratorName(op.children)} 
                                placeholder="Selecione o colaborador"></ColaboratorSelect>
                        </AppFormItem>
                        <AppFormItem
                            span={16}
                            name="modelPPCId"
                            label="Modelo PPC" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <ModelPPCSelect 
                                onSelect={(e, op) => setModelName(op.children)}
                                placeholder="Selecione o model de PPC"></ModelPPCSelect>
                        </AppFormItem>          
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default PpcGenerateFormModal;