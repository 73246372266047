import React, { useContext, useEffect, useState } from "react"
import { AppFormItem } from '../../components';
import { LayoutContext } from '../../contexts/LayoutContext';
import { DatePicker, Form, Row, Tabs } from "antd";
import { ColaboratorSelect } from "../colaborator/components";
import { ModelPPCSelect } from "../model-ppc/components";
import { PpcEvaluationForm, PpcTaskQueryPage } from "./components";
import { useSearchParams } from "react-router-dom";
import { MonthAndYearHelper } from "../../helpers";
import TabPane from "antd/es/tabs/TabPane";

const PpcFormPage = () => 
{
    const { setBreadcrumbs, setTitleAndSubTitle } = useContext(LayoutContext);   
    const [form] = Form.useForm();
    const [ searchParams ] = useSearchParams();
    const [ monthAndYear, setMonthAndYear ] = useState(null);
    const [ activeTab, setActiveTab] = useState("1");

    useEffect(() => {
        setTitleAndSubTitle('Gerenciamento de PPC', 'Gerenciamento de ppc do colaborador');
        setBreadcrumbs([        
        {
            title: 'Inicio',
            url : 'dashboard',
            isSideBarItem : true
        },
        {
            title: 'PPC',
            url : 'ppcs',
            isSideBarItem : true
        },
        {
            title: 'Gerenciamento de PPC',
            isSideBarItem : false
        }]);

        
    }, []);

    useEffect(() =>{
        if (! monthAndYear) 
        {
           setMonthAndYear(searchParams.get('monthAndYear'));
        }
    }, [monthAndYear]);
    
    return (
        <>
            { monthAndYear && 
              <>
                    <Form 
                        form={form}
                        layout="vertical"
                        name="form_ppc"
                        initialValues={{
                            monthAndYear : MonthAndYearHelper.toDate(monthAndYear), 
                            colaboratorId : searchParams.get('colaboratorId'),
                            modelPPCId : searchParams.get('modelPPCId')
                        }}
                        >
                        <Row>
                            <AppFormItem
                                span={2}
                                name="monthAndYear"
                                label="Mês Ano" 
                                rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                <DatePicker 
                                    format="MM-YYYY" 
                                    size="small" 
                                    picker="month"
                                    onChange={(_, value) => {
                                        console.log(value);
                                        setMonthAndYear(value);
                                    }}
                                    />
                            </AppFormItem>
                            <AppFormItem
                                span={11}
                                name="colaboratorId"
                                label="Colaborador" 
                                >
                                <ColaboratorSelect currentRecord={{ id : searchParams.get('colaboratorId'), name : searchParams.get('colaboratorName')}} disabled></ColaboratorSelect>
                            </AppFormItem>
                            <AppFormItem
                                span={11}
                                name="modelPPCId"
                                label="Modelo PPC" >
                                <ModelPPCSelect currentRecord={{ id : searchParams.get('modelPPCId'), name : searchParams.get('modelPPCName')}} disabled></ModelPPCSelect>
                            </AppFormItem>          
                        </Row>  

                    </Form>            
                    <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
                        <TabPane key="1" tab="Avaliações">                            
                            <PpcEvaluationForm 
                                colaboratorId={searchParams.get('colaboratorId')}
                                modelPPCId={searchParams.get('modelPPCId')}
                                monthAndYear={monthAndYear}>
                            </PpcEvaluationForm>
                        </TabPane>     

                        <TabPane key="2" tab="Atividades">                            
                            <PpcTaskQueryPage 
                                colaboratorId={searchParams.get('colaboratorId')}
                                monthAndYear={monthAndYear}>
                            </PpcTaskQueryPage>
                        </TabPane>      
                        <TabPane key="3" tab="Evidências">                            
                            <p>Aba em desenvolvimento</p>
                        </TabPane>     
                        <TabPane key="4" tab="Resultado">                            
                            <p>Aba em desenvolvimento</p>
                        </TabPane>  
                    </Tabs>
              </>  
            }
        </>
    )
}

export default PpcFormPage;