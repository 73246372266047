import React from "react";
import { ModelPPCService  } from "../../../services";
import { PaginatedSelect } from "../../../components";

const ModelPPCSelect = ({currentRecord, ...rest}) => {

  return (
    <PaginatedSelect 
        currentRecord={currentRecord ? {id : currentRecord.id, label : currentRecord.name} : null} 
        apiCallback={ModelPPCService.get} 
        statusFilter={2}
        {...rest}></PaginatedSelect>
  );
};

export default ModelPPCSelect;
