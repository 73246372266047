import axios from "axios";
import Cookies from "js-cookie";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

http.interceptors.request.use((config) => { 
    const token = Cookies.get(process.env.REACT_APP_COOKIE_TOKEN)
    if (token)
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };

    return config;    
  }, error => Promise.reject(error));

export default http;