import React, { useState } from "react"
import { Modal, Form, message, Row, Col, Typography, Tag } from 'antd';
import { AppDivider, AppFormItem, AppInput, AppInputNumber, AppSelect } from "../../../components"
import { CancelIcon, SaveIcon } from "../../../components/Icons";
import { PPCTaskService } from "../../../services";
import { JustificationSelect } from "../../justification/components";
import { TasksSelect } from "../../tasks/components";
import { ConstructorSelect } from "../../constructor/components";

const PpcTaskFormModal = ({ colaboratorId, monthAndYear, currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [ lastPPCTask, setLastPPCTtask ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [form] = Form.useForm();

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {          
                currentRecord ? 
                await PPCTaskService.update(currentRecord.id, {
                    taskId : values.taskId,
                    unitMeasureId: values.unitMeasureId,
                    name: values.name,
                    period: values.period,
                    justificationId: values.justificationId,
                    goalValue: values.goalValue,
                    doneValue: values.doneValue,
                    constructorId : values.constructorId
                }) : 
                await PPCTaskService.create(colaboratorId, {
                    taskId : values.taskId,
                    monthAndYear : monthAndYear,
                    unitMeasureId: values.unitMeasureId,
                    name: values.name,
                    period: values.period,
                    justificationId: values.justificationId,
                    goalValue: values.goalValue,
                    doneValue: values.doneValue,
                    constructorId : values.constructorId
                });              
                
                form.resetFields();
                message.success('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }

    const onSelectedTaskHandle = async (value) => {
        try 
        {           
            setLoading(true)
            const { data } = await PPCTaskService.getLast(colaboratorId, value);             
            setLastPPCTtask(data.value);
        } 
        catch (error) {
            message.error('Ocorreu uma falha para realizar operação');
            console.log(error);    
        }
        finally{
            setLoading(false);
        }
    }
    
    return (        
        <Modal 
            title="Cadastro de Atividade" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_ppc_task"
                    initialValues={currentRecord ? 
                        { 
                            taskId : currentRecord.task.id,
                            contructorId : currentRecord.constructor.id,
                            name : currentRecord.name, 
                            period : currentRecord.period, 
                            goalValue : currentRecord.goalValue,
                            doneValue :currentRecord.doneValue,
                        } : 
                        {
                            taskId : null,
                            contructorId : null,
                            name : '', 
                            period : 1, 
                            goalValue : 0
                        }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="constructorId"
                            label="Obra" 
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            >
                            <ConstructorSelect 
                                placeholder="Selecione uma obra" 
                                currentRecord={currentRecord?.constructor}></ConstructorSelect>
                        </AppFormItem>

                        <AppFormItem
                            span={20}
                            name="taskId"
                            label="Atividade" 
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            >
                            <TasksSelect 
                                placeholder="Selecione uma atividade" 
                                currentRecord={currentRecord?.task} onSelect={onSelectedTaskHandle}></TasksSelect>
                        </AppFormItem>
                        <AppFormItem
                            span={4}
                            name="period"
                            label="Semana" 
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            >
                            <AppSelect size="small" options={[ 
                                { value: 1, label: "1" },
                                { value: 2, label: "2" }, 
                                { value: 3, label: "3" },
                                { value: 4, label: "4" },
                                { value: 5, label: "5" }
                                ]}>
                            </AppSelect>
                        </AppFormItem>              
                        
                        { lastPPCTask && <AppDivider>Resumo Semana Anterior</AppDivider> } 

                        { lastPPCTask &&
                            <Col span={8} display>
                                <Typography align="start">Mês Ano : { lastPPCTask.monthAndYear ? lastPPCTask.monthAndYear : '-' }</Typography>
                            </Col> 
                        }

                        { lastPPCTask && 
                            <Col span={8}>
                                <Typography align="center">Semana : { lastPPCTask.period ? lastPPCTask.period : '-' }</Typography>
                            </Col> 
                        }

                        { 
                            lastPPCTask && 
                            <Col span={8}>
                                <Typography align="end">Faltante : 
                                    <Tag style={{marginLeft: '4px'}} color={lastPPCTask.valueToDoneGoal > 0 ? 'red' : 'green'}>
                                        {lastPPCTask.valueToDoneGoal ? lastPPCTask.valueToDoneGoal : '0'} {lastPPCTask.task.unitMeasure.name}
                                    </Tag>
                                </Typography>
                            </Col>
                        }

                        <AppDivider>Dados de Cadastro</AppDivider>

                        <AppFormItem
                            span={24}
                            name="name"
                            label="Descrição" 
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            >
                            <AppInput size="small" placeholder="Informe uma descrição"/>
                        </AppFormItem>
                        <AppFormItem
                            span={8}
                            name="goalValue"
                            label="Planejado" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInputNumber />
                        </AppFormItem>                        
                        {
                            currentRecord &&
                            <AppFormItem
                                span={8}
                                name="doneValue"
                                label="Realizado" 
                                >
                                <AppInputNumber />                        
                            </AppFormItem>  
                        }
                        {
                            currentRecord &&
                            <AppFormItem
                                span={24}
                                name="justificationId"
                                label="Justificativa">
                                <JustificationSelect placeholder="Selecione uma justificativa"/>                        
                            </AppFormItem> 
                        }
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default PpcTaskFormModal;