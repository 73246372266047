import React from "react";
import { ColaboratorService  } from "../../../services";
import { PaginatedSelect } from "../../../components";

const ColaboratorSelect = ({currentRecord, ...rest}) => {

  return (
    <PaginatedSelect 
        currentRecord={currentRecord ? {id : currentRecord.id, label : currentRecord.name} : null} 
        apiCallback={ColaboratorService.get} 
        statusFilter={1}
        {...rest}></PaginatedSelect>
  );
};

export default ColaboratorSelect;
