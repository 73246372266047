import React, {  useContext, useEffect, useState } from "react"
import { AppButton, AppTable, PageHeader } from "../../../components";
import { PPCTaskService } from "../../../services";
import { message, Tag } from "antd";
import { AuditIcon, CreateIcon, DeleteIcon, UpdateIcon } from "../../../components/Icons";
import { ConfirmModalContext } from "../../../contexts/ConfirmModalContext";
import PpcTaskFormModal from "./PpcTaskFormModal";

const PpcTaskQueryPage = ({colaboratorId, monthAndYear}) => 
{
    const { executeIfConfirm } = useContext(ConfirmModalContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);    
    const [ page, setPage ] = useState(1);
    const [ totalItems, setTotalItems ] = useState(0);
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ seletectedRecord, setSeletectedRecord ] = useState(null);

    const columns = [
        {
            title: '#Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Obra',
            dataIndex: 'constructor',
            key: 'constructor',
            render: (_) => `${_.id} - ${_.name}`
        },
        {
            title: 'Atividade',
            dataIndex: 'task',
            key: 'task',
            render: (_) => `${_.id} - ${_.name}`
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },   
        {
            title: 'Semana',
            dataIndex: 'period',
            key: 'period',
        },  
        {
            title: 'Planejado',
            dataIndex: 'goalValue',
            key: 'goalValue',
            render: (_, record) => `${_} ${record.task.unitMeasure.name}`
        },         
        {
            title: 'Realizado',
            dataIndex: 'doneValue',
            key: 'doneValue',
            render: (_, record) => _ ? `${_} ${record.task.unitMeasure.name}` : ''
        },        
        {
            title: 'Atingido',
            dataIndex: 'goalPercentage',
            key: 'goalPercentage',            
            render: (goalPercentage) => goalPercentage ? <Tag color={goalPercentage < 100 ? "red" : "green"}>{goalPercentage > 100 ? "+ 100 %" : `${goalPercentage} %`}</Tag> :<></>
        },
        {
            title: 'Justificativa',
            dataIndex: 'justification',
            key: 'justification',
            render: (_) => _ && `${_?.id} - ${_?.name}`
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) =>(
                <>
                    <AppButton onClick={() => setSeletectedRecord(record) }><AuditIcon/>Auditar</AppButton>
                    <AppButton variant="outlined" onClick={() => onDeleteHandler(record.id) }><DeleteIcon/></AppButton>
                </>
            )
        }
    ];

    const loadData = async() => {
        try 
        {
            setLoading(true);   
            const { data } = await PPCTaskService.get(colaboratorId, monthAndYear, page, 20);
            setData(data.value.values);
            setTotalItems(data.value.total);
        } 
        catch(error) 
        {
            message.error('Ocorreu uma falha para consultar os ppc do colaborador');
            console.log(error);            
        }
        finally{
           setLoading(false)
        }
    }

    const onDeleteHandler = (id) => {
        executeIfConfirm( "Atenção", "Tem certeza que deseja excluir a atividade ?", async () => 
        {
            try 
            {
                setLoading(true);   
                await PPCTaskService.delete(id);
                loadData();
            } 
            catch(error) 
            {
                message.error('Ocorreu uma falha para delete a unidade de medida');
                console.log(error);            
            }
            finally{
               setLoading(false)
            }
        });
    }

    useEffect(() => { 
        if (seletectedRecord) setIsOpenFormModal(true) 
    }, 
    [seletectedRecord])

    useEffect(() => {
        if (monthAndYear) loadData();
    }, [monthAndYear]);

    return (
        <>          

            <PageHeader>
                <AppButton onClick={() => setIsOpenFormModal(true) }><CreateIcon/>Novo</AppButton>
            </PageHeader> 
            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                totalItems={totalItems}
                data={data}
                onPageChange={(newPage) => setPage(newPage)}/> 
                {
                    isOpenFormModal && 
                    <PpcTaskFormModal 
                    monthAndYear={monthAndYear}
                    colaboratorId={colaboratorId}
                    onCancel={() => setIsOpenFormModal(false)}
                    onAfterOperation={() => {
                        setSeletectedRecord(null);
                        setIsOpenFormModal(false);
                        loadData();
                    }}
                    currentRecord={seletectedRecord}
                    ></PpcTaskFormModal> 
                }
        </>
    )
}

export default PpcTaskQueryPage;