import React from 'react';
import { Layout } from 'antd';
import { AppSiderbar, AppTitlePage, AppBreadcrumb, AppHeader } from './';

const { Content } = Layout;

function LoggedLayout({children}) {
  return (
    <Layout>
      <AppHeader></AppHeader>          
      <Layout>
        <AppSiderbar/> 
        <Layout style={{ padding: '0 24px 24px' }}>
          <AppTitlePage></AppTitlePage>
          <AppBreadcrumb></AppBreadcrumb>
          
          <Content style={{
              padding: 12,
              margin: 0,
              minHeight: 768,
              background: "#fff",
            }}>
            {children}
          </Content> 
        </Layout>           
      </Layout>        
    </Layout>
  );
}

export default LoggedLayout;
