import React, { useState } from "react"
import { Modal, Form, message, Row, DatePicker } from 'antd';
import { AppFormItem,  } from "../../../components"
import { CancelIcon, SaveIcon } from "../../../components/Icons";
import { PPCEvaluationService } from "../../../services";

const PpcEvaluationAddNewDateFormModal = ({ colaboratorId, modelPPCId, onCancel, onAfterOperation, ...rest}) => 
{
    const [ loading, setLoading ] = useState(false);
    const [form] = Form.useForm();

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                
                await PPCEvaluationService.create(colaboratorId, 
                { 
                    modelPPCId : modelPPCId,
                    date : values.date 
                });
                
                form.resetFields();
                message.success('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }            
            finally
            {                
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Adicionar Dia" 
            open 
            width={235}
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_ppc_evaluation_add_new_date"
                    initialValues={ { date : null }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="date"
                            label="Informe a data" 
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            >
                            <DatePicker size="small" placeholder="Selecione uma data" ></DatePicker>
                        </AppFormItem>                      
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default PpcEvaluationAddNewDateFormModal;