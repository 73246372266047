import React, { useState, useContext, useEffect } from "react"
import { PageHeader, AppButton, AppTable, PageFilter, DefaultFilterForm } from '../../components';
import { UpdateIcon, FilterIcon, CreateIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import ModelPPCFormModal from "./ModelPPCFormModal";
import { ModelPPCService } from "../../services";
import { message } from "antd";

const ModelPPCQueryPage = () => 
{
    const [ isFilterVisible, setIsFilterVisible ] = useState(false);
    const { setBreadcrumbs, setTitleAndSubTitle } = useContext(LayoutContext);
    const [ totalItems, setTotalItems ] = useState(0);
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ seletectedRecord, setSeletectedRecord ] = useState(null);
    const [ loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ filter, setFilter ] = useState({ search : '', status : 1});
    
    const [ page, setPage ] = useState(1);

    const columns = [
        {
            title: '#Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },    
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => record.status === 1 ? "Aberto" : record.status === 2 ? "Aprovado" : "Inativo"
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) =>(
                <>
                    <AppButton onClick={() => setSeletectedRecord(record) }><UpdateIcon/>Editar</AppButton>
                </>
            )
        }
    ];

    const loadData = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await ModelPPCService.get(filter, page, 20);
            setData(data.value.values);            
            setTotalItems(data.value.total);
        } 
        catch(error) 
        {
            message.error('Ocorreu uma falha para consultar os modelo de ppc');
            console.log(error);            
        }
        finally{
           setLoading(false)
        }
    }

    useEffect(() => {
        setTitleAndSubTitle('Modelos de PPC', 'Gerenciamento dos modelo de PPC');
        setBreadcrumbs([        
        {
            title: 'Inicio',
            url : 'dashboard',
            isSideBarItem : true
        },
        {
            title: 'Modelos PPC',
            url : 'ppc-models',
            isSideBarItem : true
        }]);
    }, []);
    
    useEffect(() => {
        loadData();
    }, [filter, page]);

    useEffect(() => { 
        if (seletectedRecord) setIsOpenFormModal(true);
    }, 
    [seletectedRecord])

    const onAfterOperationHandler = async() => {
        await resetCrud();
        await  loadData();
    }    
    
    const resetCrud = async () => {        
        await setSeletectedRecord(null);
        await setIsOpenFormModal(false);
    }

    return (
        <>
            <PageHeader>
                <AppButton onClick={() => setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => setIsOpenFormModal(true) } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>
                    
                    <DefaultFilterForm 
                    initialValues={filter} 
                    onFilter={(values) => setFilter(values)}
                    statusOptions={[ 
                        { value: 1, label: "Ativo" }, 
                        { value: 2, label: "Aprovado" },
                        { value: 0, label: "Inativo" }]} />
                </PageFilter>
            </PageHeader>     
            
             <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                totalItems={totalItems}                
                data={data}
                onPageChange={(newPage) => setPage(newPage)}/>     

                {
                    isOpenFormModal ?                 
                    <ModelPPCFormModal 
                        currentRecord={seletectedRecord}
                        onCancel={async  () => await resetCrud() }
                        onAfterOperation={async () => await onAfterOperationHandler()}>
                    </ModelPPCFormModal> : <></>
                }          
        </>
    )
}

export default ModelPPCQueryPage;