import React, {useContext, useState} from 'react';
import { Button, Form, Input,  Flex, Image} from 'antd'
import { AuthContext } from '../../contexts/AuthContext';

const LoginPage = () => {
    const { login } = useContext(AuthContext);
    const [ openForgetPassword, setOpenForgetPassword ] = useState(false);

    return (    
        <Flex justify='center' align='center' >
            <div style={{  
                    paddingTop : '24px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderColor: "#fff5",
                    borderRadius: '8px',
                    textAlign: 'center'}}>
                <Image 
                    style={{ align: 'center'}} 
                    src="logo.png" 
                    width={300} 
                    height={85}>                        
                </Image>

                <Form
                    name="basic"
                    onFinish={(values) => login(values.email, values.password)}
                    autoComplete="off"
                    style={{ maxWidth: 300, marginTop: '16px' }}
                >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Informe seu CNPJ!' }]}
                >
                    <Input placeholder="Informe seu CNPJ" />
                </Form.Item>
        
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Informe sua senha' }]}
                >
                    <Input.Password placeholder="Informe sua senha" />
                </Form.Item>
        
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Acessar
                    </Button>
                </Form.Item>
        
                <Form.Item align="right">
                    <a onClick={() => setOpenForgetPassword(true)}>Esqueceu sua senha?</a>
                </Form.Item>
                </Form>
            </div>
            
      </Flex>
)
}

export default LoginPage;