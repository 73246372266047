     

import React, { useContext } from 'react';
import { Layout, Dropdown, Button } from 'antd';
import { DownOutlined, LockOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../contexts/AuthContext';


const { Header } = Layout;

function AppHeader() {
    const { logoff } = useContext(AuthContext)

    const menuProps = {
        items : [
        {
            label: 'Gerencia Usuários',
            key: '1',
            icon: <UserOutlined />,
        },
        {
            label: 'Troca de Senha',
            key: '2',
            icon: <LockOutlined />,
        },
        {
            label: 'Sair',
            key: '3',
            icon: <PoweroffOutlined />
        }
        ],
        onClick: ({key}) => key === '1' ? logoff() : 
                            key === '2' ? logoff() : 
                            logoff(),
      };

  return (
    <Header 
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#ff6600",
            padding: "0 20px",
        }}>                            
        <div style={{ color: "#fff", fontSize: "18px", fontWeight: "bold" }}>
            Supreme
        </div>

        <Dropdown
            menu={menuProps}
            placement="bottomRight"
            trigger={['click']}
            arrow
        >
            <Button size='normal' style={{borderRadius : '50px', padding: '12px 10px 12px 10px'}}><DownOutlined /></Button>
        </Dropdown>
    </Header>  
  );
}

export default AppHeader;
