import { http } from '.';

const ConstructorService = {
    get: (filter, page, size) => http.get(`constructor?search=${filter.search}&status=${filter.status}&page=${page}&pageSize=${size}`),    
    create: (model) => http.post('constructor', model),
    update: (id, model) => http.put(`constructor/${id}`, model)
}

export default ConstructorService;

