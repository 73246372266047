import React, { useContext } from 'react';
import { Modal } from 'antd';
import { AlertIcon, CancelIcon, ConfirmIcon } from './Icons';
import { ConfirmModalContext } from '../contexts/ConfirmModalContext';

const ConfirmModal = ({...rest}) => 
{
    const {title, text, isOpen, confirm, close} = useContext(ConfirmModalContext);

    return(
        <Modal 
            open={isOpen}
            title={title}         
            onOk={confirm}
            onCancel={() => close() }
            okText="Confirmar"
            okButtonProps={{
                size: "small",
                icon: <ConfirmIcon/>
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar"            
            {...rest}>               
            <p><AlertIcon style={{ marginRight: 6, fontSize: 16}}/>{text}</p>
        </Modal>
    )
};

export default ConfirmModal;