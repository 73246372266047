import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

function AnonymousLayout({children}) {
  return (
    <Layout>
        <Content style={{
            padding: 24,
            margin: 0,
            minHeight: 480,
            background: "#fff",
        }}>
        {children}
        </Content>    
    </Layout>
  );
}

export default AnonymousLayout;
