import React, { useState, useContext, useEffect } from "react"
import { PageHeader, AppButton, AppTable, PageFilter, DefaultFilterForm } from '../../components';
import { UpdateIcon, FilterIcon, CreateIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import { ColaboratorService } from "../../services";
import { message } from "antd";
import { useNavigate } from 'react-router-dom';
import { PpcGenerateFormModal } from "./components";

const PpcQueryPage = () => 
{
    const [ loading, setLoading] = useState(false);
    const [ isFilterVisible, setIsFilterVisible ] = useState(false);
    const { setBreadcrumbs, setTitleAndSubTitle } = useContext(LayoutContext);   
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ totalItems, setTotalItems ] = useState(0);    
    const [ data, setData ] = useState([]);
    const [ filter, setFilter ] = useState({ search : '', status : 1})
    const [ page, setPage ] = useState(1);
    const navigate = useNavigate();

    const columns = [
        {
            title: '#Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },       
        {
            title: 'Documento',
            dataIndex: 'document',
            key: 'document',
        },
        {
            title: 'Cargo',
            dataIndex: 'job',
            key: 'job',
            render: (_, record) => record.job.name
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => record.status ? "Ativo" : "Inativo"
        },
        {
            title: 'Ultimo PPC',
            dataIndex: 'monthAndYear',
            key: 'monthAndYear',            
            render: (_, record) => record.monthAndYear ? record.monthAndYear : "Nunca gerado"
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) => record.monthAndYear ? 
                <AppButton onClick={() => 
                    navigate(`/ppc-form?colaboratorId=${record.id}&colaboratorName=${record.name}&monthAndYear=${record.monthAndYear}&modelPPCId=${record.modelPPC.id}&modelPPCName=${record.modelPPC.name}`) 
                }><UpdateIcon/>Editar</AppButton> : 
                <></>
        }
    ];

    const loadData = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await ColaboratorService.getPPCS(filter, page, 20);
            setData(data.value.values);
            setTotalItems(data.value.total);
        } 
        catch(error) 
        {
            message.error('Ocorreu uma falha para consultar os ppc dos colaboradores');
            console.log(error);            
        }
        finally{
           setLoading(false)
        }
    }

    useEffect(() => {
        setTitleAndSubTitle('PPC', 'Gerenciamento de ppc por colaborador');
        setBreadcrumbs([        
        {
            title: 'Inicio',
            url : 'dashboard',
            isSideBarItem : true
        },
        {
            title: 'ppc',
            url : 'ppcs',
            isSideBarItem : true
        }]);
    }, []);
    
    useEffect(() => {
        loadData();
    }, [filter, page]);

    const onAfterOperationHandler = (value) => 
    {
        setIsOpenFormModal(false);
        navigate(`/ppc-form?colaboratorId=${value.colaboratorId}&colaboratorName=${value.colaboratorName}&monthAndYear=${value.monthAndYear}&modelPPCId=${value.modelPPCId}&modelPPCName=${value.modelPPCName}`) 
    }    

    return (
        <>
            <PageHeader>
                <AppButton onClick={() =>  setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => setIsOpenFormModal(true) } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>                    
                    <DefaultFilterForm initialValues={filter} onFilter={(values) => setFilter(values)} />
                </PageFilter>
            </PageHeader>              

            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                totalItems={totalItems}
                data={data}
                onPageChange={(newPage) => setPage(newPage)}/>   
                {
                isOpenFormModal ? 
                    <PpcGenerateFormModal
                        onCancel={() => setIsOpenFormModal(false) }
                        onAfterOperation={onAfterOperationHandler}>
                    </PpcGenerateFormModal>  :<></>  
                }
        </>
    )
}

export default PpcQueryPage;