import React, { useContext } from 'react';
import { Typography } from 'antd';
import { LayoutContext } from '../contexts/LayoutContext'

const AppTitlePage = () => {
    const {title, subTitle} = useContext(LayoutContext);

    return (  
        <div style={{ marginBottom : 8, marginTop: 8 }}>            
            <Typography.Title level={2} style={{
                marginBottom: 0,
                marginTop: 8,
                lineHeight: 1
            }}>
                {title}
            </Typography.Title>
            {
                subTitle && 
                <Typography.Text style={{ fontSize: 12 }}>{subTitle}</Typography.Text>
            }
        </div>
    )
}
export default AppTitlePage