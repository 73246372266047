import React from 'react';
import { createContext, useState } from "react";

export const LayoutContext = createContext();

export const LayoutContextProvider = ({children}) => {
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [breadcrumbs, setBreadcrumbsState] = useState([]);


    function setTitleAndSubTitle (title, subTitle)
    {
        setTitle(title);
        setSubTitle(subTitle);
    }

    function setTitlePage (title)
    {
        setTitle(title);
        setSubTitle('');
    }

    function setBreadcrumbs (breadcrumbs)
    {
        setBreadcrumbsState([ ...breadcrumbs ]);
    }

    return (
        <LayoutContext.Provider value={{ 
                title, 
                subTitle,
                breadcrumbs,
                setTitleAndSubTitle,
                setTitlePage,
                setBreadcrumbs
            }}>
            { children }
        </LayoutContext.Provider>
    )
}