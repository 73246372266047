import React from 'react';
import { InputNumber } from 'antd';

const AppInputNumber = ({...rest}) => {
  const formatter = (value) =>
    new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value || 0);

  const parser = (value) =>
    value ? parseFloat(value.replace(/\./g, '').replace(',', '.')) : 0;

  return (
    <InputNumber
      size='small'
      formatter={formatter}
      parser={parser}
      step={0.01}
      style={{ width: '100%' }}
      {...rest}
    />
  );
};

export default AppInputNumber;