import React from "react";
import { UnitMeasureService } from "../../../services";
import { PaginatedSelect } from "../../../components";

const UnitMeasureSelect = ({currentRecord, ...rest}) => {

  return (
    <PaginatedSelect
        currentRecord={currentRecord ? {id : currentRecord.id, label : currentRecord.name} : null} 
        apiCallback={UnitMeasureService.get} 
        statusFilter={1}
        {...rest}></PaginatedSelect>
  );
};

export default UnitMeasureSelect;
