import React, { useState } from "react"
import { Modal, Form, message, Row } from 'antd';
import { AppFormItem, AppInput } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { UnitMeasureService } from "../../services";

const UnitMeasureFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [ loading, setLoading ] = useState(false);
    const [form] = Form.useForm();

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                currentRecord ? await UnitMeasureService.update(currentRecord.id, values) : await UnitMeasureService.create(values);              
                
                form.resetFields();
                message.success('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }
            finally
            {
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Unidade de Medida" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading : {loading}
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_job"
                    initialValues={currentRecord ? {...currentRecord} : { name : '' }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Compo obrigatório' }]}
                            >
                            <AppInput size="small" placeholder="Nome da unidade de medida"/>
                        </AppFormItem>  
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default UnitMeasureFormModal;