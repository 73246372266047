import { http } from '.';

const UnitMeasureService = { 
    get : (filter, page, size) => http.get(`unit-measure?search=${filter.search}&page=${page}&pageSize=${size}`),
    create : (model) => http.post(`unit-measure`, model),
    update : (unitMeasureId, model) => http.put(`unit-measure/${unitMeasureId}`, model),
    delete : (unitMeasureId) => http.delete(`unit-measure/${unitMeasureId}`)
}

export default UnitMeasureService;
