import React from 'react';
import { Col, Form } from 'antd';

const AppFormItem = ({span, children, ...rest}) => {
    return (
        <Col span={span ? span : 24} style={{ paddingLeft: 2, paddingRight: 2}}>
            <Form.Item style={{ margin: 0, marginBottom: 6}} {...rest}>
                {children}
            </Form.Item>
        </Col>
    )
}

export default AppFormItem;