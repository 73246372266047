import React, {  useEffect, useState } from "react"
import { AppButton, AppTable, PageHeader } from "../../../components";
import { PPCEvaluationService } from "../../../services";
import { Checkbox, Dropdown, message, Tag } from "antd";
import { CreateIcon, DeleteIcon, ManagementIcon } from "../../../components/Icons";
import { PpcEvaluationDeleteDateFormModal, PpcEvaluationAddNewDateFormModal } from "./";

const PpcEvaluationForm = ({colaboratorId, modelPPCId, monthAndYear}) => 
{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [ showAddNewDateModal, setshowAddNewDateModal ] = useState(false);
    const [ showDeleteDateModal, setShowDeleteDateModal ] = useState(false);

    const menuProps = {
        items : [
            {
                label: 'Adicionar Dia',
                key: '1',
                icon: <CreateIcon />,
            },
            {
                label: 'Remover Dia',
                key: '2',
                icon: <DeleteIcon />,
            }
        ],
        onClick: ({key}) => key === '1' ? setshowAddNewDateModal(true) : setShowDeleteDateModal(true)
      };


    const populateColumns = (daysInTheMonth) =>
    {            
        const _columns = [{
            title: 'Avaliação',
            dataIndex: 'name',
            key: 'name',
            width: 150
        }];

        for (let currentDay = 1; currentDay<=daysInTheMonth; currentDay++)
        {
            const currentDayString = currentDay < 10 ? `0${currentDay}` : `${currentDay}`;
            _columns.push({
                title: currentDayString,
                dataIndex:  `day${currentDayString}`,
                key: `day${currentDayString}`,
                width: 15,
                align: 'center',
                render: (currentDay, record) => 
                <div className="td-without-padding"> {
                    ! currentDay ? <p>-</p> :
                         record.modelPpcItemId ? 
                            <Checkbox 
                                checked={currentDay.done} 
                                onChange={({target}) => checkEvaluationHandler(currentDay.day, target.checked, record.modelPpcItemId)}>                                                    
                            </Checkbox> : 
                                <Tag color={currentDay.summary <= 50 ? "red" : "green"}>{currentDay.summary}</Tag> 
                }
                </div>
            })
        }

        _columns.push({
            title: '% Mês',
            dataIndex:  'summary',
            key: 'summary',
            width: 35,
            align: 'right',
            render: (summary) => <Tag color={summary <= 50 ? "red" : "green"}>{summary}</Tag>
        })
        setColumns(_columns);
    }

    const loadData = async() => {
        try 
        {
            setLoading(true);   
            const { data } = await PPCEvaluationService.getByMonthAndYear(colaboratorId, monthAndYear);
            populateColumns(data.value.daysInTheMonth);            
            setData(data.value.items);
        } 
        catch(error) 
        {
            message.error('Ocorreu uma falha para consultar os ppc do colaborador');
            console.log(error);            
        }
        finally{
           setLoading(false)
        }
    }

    const checkEvaluationHandler = async (day, done,  modelPpcItemId) => 
    {        
        try 
        {
            setLoading(true);   
            const month = Number.parseInt( monthAndYear.split("-")[0]) - 1;
            const year = monthAndYear.split("-")[1];
            const model = {
                date : new Date(year, month, day),
                done : done,
                modelPpcItemId : modelPpcItemId
            }
            await PPCEvaluationService.check(colaboratorId, model);
            loadData();
        } 
        catch(error) 
        {
            message.error('Ocorreu uma falha para consultar os ppc do colaborador');
            console.log(error);            
        }
        finally{
           setLoading(false)
        }

    }
    useEffect(() => {
        if (monthAndYear) loadData();
    }, [monthAndYear]);

    return (
        <>
            <PageHeader>
                <Dropdown
                    menu={menuProps}
                    placement="bottomRight"
                    trigger={['click']}
                    arrow
                >
                    <AppButton><ManagementIcon/>Gerenciar</AppButton>
                </Dropdown>
                
            </PageHeader>              

            <AppTable             
                rowKey={record => record.modelPpcItemId}
                loading={loading} 
                columns={columns} 
                disabledPagination
                data={data}/>

            {
                showAddNewDateModal ? 
                <PpcEvaluationAddNewDateFormModal
                    colaboratorId={colaboratorId}
                    modelPPCId={modelPPCId}
                    onCancel={() => setshowAddNewDateModal(false)}
                    onAfterOperation={()=> {
                        setshowAddNewDateModal(false);
                        loadData();
                    }}>
                </PpcEvaluationAddNewDateFormModal> : <></>
            }    

            {
                showDeleteDateModal ? 
                <PpcEvaluationDeleteDateFormModal
                    colaboratorId={colaboratorId}
                    modelPPCId={modelPPCId}
                    onCancel={() => setShowDeleteDateModal(false)}
                    onAfterOperation={()=> {
                        setShowDeleteDateModal(false);
                        loadData();
                    }}>
                </PpcEvaluationDeleteDateFormModal> : <></>
            }   
        </>
    )
}

export default PpcEvaluationForm;