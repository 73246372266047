import React from 'react';
import { Divider } from 'antd';

const PageHeader = ({children}) => {
    return (
        <>
            { children }
            <Divider style={ { marginBottom: 6, marginTop: 6 }}></Divider>
        </>
    )
}

export default PageHeader;