import { http } from '.';

const TasksService = {
    get : (filter, page, size) => http.get(`tasks?search=${filter.search}&status=${filter.status}&page=${page}&pageSize=${size}`),
    create: (model) => http.post('tasks', model),
    update: (id, model) => http.put(`tasks/${id}`, model),
}

export default TasksService;

