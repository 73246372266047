import React from 'react';
import { createContext, useState } from "react";

export const ConfirmModalContext = createContext();

export const ConfirmModalContextProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({
        title: '',
        text: '',
        callback: null
    });


    function executeIfConfirm (title, text, callback)
    {
        setData({
            title,
            text,
            callback
        });
        setIsOpen(true);
    }

    function close() 
    {
        setData({
            title: '',
            text: '',
            callback: null
        });
        setIsOpen(false);
    }

    function confirm() 
    {
        data.callback();
        close();
    }

    return (
        <ConfirmModalContext.Provider value={{ 
                title : data.title, 
                text : data.text,
                isOpen,
                executeIfConfirm,
                close,
                confirm
            }}>
            { children }
        </ConfirmModalContext.Provider>
    )
}