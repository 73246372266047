import React, { useState, useContext, useEffect, useCallback} from "react"
import { PageHeader, AppButton, AppTable, PageFilter, DefaultFilterForm } from '../../components';
import { UpdateIcon, FilterIcon, CreateIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import { JobService } from "../../services";
import { message } from "antd";
import { JobFormModal } from ".";

const JobQueryPage = () => 
{
    const [ isFilterVisible, setIsFilterVisible ] = useState(false);
    const { setBreadcrumbs, setTitleAndSubTitle } = useContext(LayoutContext);
    const [ totalItems, setTotalItems ] = useState(0);
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ seletectedRecord, setSeletectedRecord ] = useState(null);
    const [ loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ filter, setFilter ] = useState({ search : '', status : 1})
    const [ page, setPage ] = useState(1);

    const columns = [
        {
            title: '#Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },        
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => record.status ? "Ativo" : "Inativo"
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) =>(
                <>
                    <AppButton onClick={() => setSeletectedRecord(record) }><UpdateIcon/>Editar</AppButton>
                </>
            )
        }
    ];

    const loadData = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await JobService.get(filter, page, 20);
            setData(data.value.values);
            setTotalItems(data.value.total);
        } 
        catch(error) 
        {
            message.error('Ocorreu uma falha para consultar os cargos');
            console.log(error);            
        }
        finally{
           setLoading(false)
        }
    }

    useEffect(() => {
        setTitleAndSubTitle('Cargos', 'Gerenciamento de cargo dos colaboradores');
        setBreadcrumbs([        
        {
            title: 'Inicio',
            url : 'dashboard',
            isSideBarItem : true
        },
        {
            title: 'Cargos',
            url : 'jobs',
            isSideBarItem : true
        }]);
    }, []);

    useEffect(() => {
        loadData();
    }, [filter, page]);

    useEffect(() => { 
        if (seletectedRecord) setIsOpenFormModal(true) 
    }, 
    [seletectedRecord])

    const onAfterOperationHandler = async () => {
        resetCrud();
        loadData();
    }    
    
    const resetCrud = () => {        
        setSeletectedRecord(null);
        setIsOpenFormModal(false);
    }

    return (
        <>
            <PageHeader>
                <AppButton onClick={() => setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => setIsOpenFormModal(true) } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>                    
                    <DefaultFilterForm initialValues={filter} onFilter={(values) => setFilter(values)} />
                </PageFilter>
            </PageHeader>     

             <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                totalItems={totalItems}
                data={data}
                onPageChange={(newPage) => setPage(newPage)}/> 
            {
                isOpenFormModal ? 
                <JobFormModal 
                    currentRecord={seletectedRecord}
                    onCancel={() => resetCrud() }
                    onAfterOperation={() => onAfterOperationHandler()}>
                </JobFormModal>  :<></>  
            }
                       
        </>
    )
}

export default JobQueryPage;