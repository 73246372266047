import * as dayjs from 'dayjs'

export default {
    toDate(monthAndYear)
    {
        const monthAndYearSplited = monthAndYear.split("-");
        const _date = new Date(monthAndYearSplited[1], Number.parseInt(monthAndYearSplited[0]) - 1, 1);
        return dayjs(_date);
    },
    dateToMonthAndyear(date)
    {
        const splittedDate = date.toISOString().split("-");          
        return `${splittedDate[1]}-${splittedDate[0]}`;
    }
}