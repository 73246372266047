import { http } from '.';

const PPCTaskService = { 
    get : (colaboratorId, monthAndYear, page, size) => http.get(`ppc-task/${colaboratorId}?monthAndYear=${monthAndYear}&page=${page}&pageSize=${size}`), 
    getLast : (colaboratorId, taskId) => http.get(`ppc-task/${colaboratorId}/task/${taskId}/last-period-lacking-values`), 
    create : (colaboratorId, model) => http.post(`ppc-task/${colaboratorId}`, model),
    update : (colaboratorId, model) => http.put(`ppc-task/${colaboratorId}`, model),
    delete : (ppcTaskId) => http.delete(`ppc-task/${ppcTaskId}`)
}

export default PPCTaskService;
