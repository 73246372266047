import React from 'react';
import {Card} from 'antd';

const PageFilter = ({isVisible, children, ...rest}) => {
    return (
        isVisible ?
        <Card style={{ marginTop: 6 }} bodyStyle={{ padding: 6}} {...rest}>
            {children}
        </Card> : <></>
    )
};

export default PageFilter;