import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd';
import { LayoutContext } from '../contexts/LayoutContext'

const AppBreadcrumb = () => {
    const { breadcrumbs } = useContext(LayoutContext);

    return (  
        <Breadcrumb style={{
            marginBottom: 4
        }}>
            {breadcrumbs && breadcrumbs.map((currentItem, index) => 
                <Breadcrumb.Item key={index}>
                    <Link to={currentItem.url}>{currentItem.title}</Link>
                </Breadcrumb.Item>                
            )}
        </Breadcrumb>
    )
}
export default AppBreadcrumb