import React, { useState } from "react"
import { Modal, Form, message, Row } from 'antd';
import { AppFormItem, AppInput, AppSelect } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { ConstructorService } from "../../services";
import { ColaboratorSelect } from "../colaborator/components";

const ConstructorFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                currentRecord ? 
                    await ConstructorService.update(currentRecord.id, values) : 
                    await ConstructorService.create({
                        name : values.name,
                        responsibleId : values.responsibleId
                    });              
                
                form.resetFields();
                message.success('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }
            finally
            {                
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Obras" 
            open 
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading : {loading}
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_constructor"
                    initialValues={
                        currentRecord ? 
                        { name : currentRecord.name, status : currentRecord.status, responsibleId: currentRecord.responsible.id } : 
                        { name : '', status : 1, responsibleId: null }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInput placeholder="Informe o nome da obra"/>
                        </AppFormItem>
                        <AppFormItem
                            span={16}
                            name="responsibleId"
                            label="Responsável" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <ColaboratorSelect placeholder="Selecione o responsável" currentRecord={currentRecord?.responsible}></ColaboratorSelect>
                        </AppFormItem>
                        <AppFormItem
                            span={8}
                            name="status"
                            label="Status" 
                            >
                            <AppSelect disabled={! currentRecord} size="small" options={[ { value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }]}></AppSelect>
                        </AppFormItem>           
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default ConstructorFormModal;