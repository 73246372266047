import React, { useState, useContext, useEffect } from "react"
import { PageHeader, AppButton, AppTable, PageFilter, DefaultFilterForm } from '../../components';
import { UpdateIcon, FilterIcon, CreateIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import { TasksService } from "../../services";
import { message } from "antd";
import { TasksFormModal } from ".";

const TasksQueryPage = () => 
{
    const [ isFilterVisible, setIsFilterVisible ] = useState(false);
    const { setBreadcrumbs, setTitleAndSubTitle } = useContext(LayoutContext);
    const [ totalItems, setTotalItems ] = useState(0);
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ seletectedRecord, setSeletectedRecord ] = useState(null);
    const [ loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ filter, setFilter ] = useState({ search : '', status : 1})
    const [ page, setPage ] = useState(1);

    const columns = [
        {
            title: '#Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },              
        {
            title: 'Un.Medida',
            dataIndex: 'unitMeasure',
            key: 'unitMeasure',
            render: (unitMeasure) => unitMeasure ? `${unitMeasure.id} - ${unitMeasure.name}` : ''
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => record.status ? "Ativo" : "Inativo"
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) =>(
                <>
                    <AppButton onClick={() => setSeletectedRecord(record) }><UpdateIcon/>Editar</AppButton>
                </>
            )
        }
    ];

    const loadData = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await TasksService.get(filter, page, 20);
            setData(data.value.values);
            setTotalItems(data.value.total);
        } 
        catch(error) 
        {
            message.error('Ocorreu uma falha para consultar as atividades');
            console.log(error);            
        }
        finally{
           setLoading(false)
        }
    }

    useEffect(() => {
        setTitleAndSubTitle('Atividades', 'Gerenciamento das atividades');
        setBreadcrumbs([        
        {
            title: 'Inicio',
            url : 'dashboard',
            isSideBarItem : true
        },
        {
            title: 'Atividades',
            url : 'tasks',
            isSideBarItem : true
        }]);
    }, []);

    useEffect(() => {
        loadData();
    }, [filter, page]);

    useEffect(() => { 
        if (seletectedRecord) setIsOpenFormModal(true) 
    }, 
    [seletectedRecord])

    const onAfterOperationHandler = async () => {
        resetCrud();
        loadData();
    }    
    
    const resetCrud = () => {        
        setSeletectedRecord(null);
        setIsOpenFormModal(false);
    }

    return (
        <>
            <PageHeader>
                <AppButton onClick={() => setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => setIsOpenFormModal(true) } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>                    
                    <DefaultFilterForm initialValues={filter} onFilter={(values) => setFilter(values)} />
                </PageFilter>
            </PageHeader>     

             <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                totalItems={totalItems}
                data={data}
                onPageChange={(newPage) => setPage(newPage)}/> 
            {
                isOpenFormModal ? 
                <TasksFormModal 
                    currentRecord={seletectedRecord}
                    onCancel={() => resetCrud() }
                    onAfterOperation={() => onAfterOperationHandler()}>
                </TasksFormModal>  :<></>  
            }
                       
        </>
    )
}

export default TasksQueryPage;