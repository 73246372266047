import React, { useState } from "react"
import { Modal, Form, message, Row, Col, Typography,  } from 'antd';
import { AppButton, AppDivider, AppFormItem, AppInput, AppSelect } from "../../components"
import { CancelIcon, CreateIcon, DeleteIcon, SaveIcon } from "../../components/Icons";
import { ModelPPCService } from "../../services";

const ModelPPCFormModal = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                setLoading(true);
                currentRecord ? await ModelPPCService.update(currentRecord.id, values) : await ModelPPCService.create(values);              
                
                form.resetFields();
                message.success('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }
            finally
            {
                setLoading(false)
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }

    const isLastItem = (index) => {
        const items =form.getFieldValue('items');
        return items.length === (index + 1);
    } 
    
    return (        
        <Modal 
            title="Cadastro de Modelo PPC" 
            open            
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: {loading}
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_model_ppc"
                    initialValues={currentRecord ? {...currentRecord} : { 
                        name : '', 
                        description : '', 
                        discountOfColaborator : false, 
                        status : 1, 
                        items: [{ id : 0, name : '', percentage : 0 }] }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={18}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Por favor informe o nome da justificativa' }]}
                            >
                            <AppInput size="small" placeholder="Informe o nome da justificativa"/>
                        </AppFormItem>
                        <AppFormItem
                            span={6}
                            name="status"
                            label="Status" 
                            >
                            <AppSelect disabled={! currentRecord} size="small" options={[ 
                                { value: 1, label: "Aberto" }, 
                                { value: 2, label: "Aprovado" }, 
                                { value: 0, label: "Inativo" }]}></AppSelect>
                        </AppFormItem>         
                    </Row>             
                    <AppDivider>Avaliações</AppDivider>
                    <Row>
                        <Col span={18}>
                            <Typography>Nome</Typography>
                        </Col>
                        <Col span={6}>
                            <Typography>Divisão %</Typography>
                        </Col>
                    </Row>
                    <Form.List name="items">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name }, index) => (
                                <Row key={key}>
                                    <AppFormItem
                                        span={18}
                                        name={[name, "name"]}
                                        rules={[{ required: true, message: "Nome obrigatório!" }]}
                                        >
                                        <AppInput placeholder="Nome" />
                                    </AppFormItem>
                                    <AppFormItem
                                        span={5}
                                        name={[name, "percentage"]}
                                        rules={[
                                            { required: true, message: "Divisão % obrigatória!" },
                                            {
                                                pattern: /^[0-9]+$/,
                                                message: "Somente números!",
                                            },
                                        ]}
                                        >
                                        <AppInput placeholder="Divisão %" />
                                    </AppFormItem>
                                    <AppFormItem span={1}>
                                        {
                                            isLastItem(index) ?
                                            <AppButton onClick={() => add({ id : 0, name : '', percentage : null })} icon={<CreateIcon/>}></AppButton> :
                                            <AppButton color="danger" onClick={() => remove(index)} icon={<DeleteIcon/>}></AppButton>
                                        }                                        
                                    </AppFormItem>
                                </Row>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form>                                
        </Modal>       
    )
}

export default ModelPPCFormModal;