import React, { useState } from "react"
import { Modal, Form, message, Row } from 'antd';
import { AppFormItem, AppInput, AppSelect } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { JustificationService } from "../../services";
import TextArea from "antd/es/input/TextArea";

const CategoryModalForm = ({currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {         
                setLoading(true);  
                currentRecord ? await JustificationService.update(currentRecord.id, values) : await JustificationService.create(values);              
                
                form.resetFields();
                message.success('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }
            finally{
                setLoading(false);
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Justificativa" 
            open            
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading : { loading }
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_justification"
                    initialValues={currentRecord ? {...currentRecord} : { name : '', description : '', discountOfColaborator : false, status : 1 }}
                    {...rest}>
                    <Row>
                        <AppFormItem
                            span={24}
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                            <AppInput size="small" placeholder="Informe o nome da justificativa"/>
                        </AppFormItem>

                        <AppFormItem
                            span={24}
                            name="description"
                            label="Descrição" 
                            >
                            <TextArea size="small" placeholder="Informe a descrição"/>
                        </AppFormItem>
                        <AppFormItem
                            span={8}
                            name="discountOfColaborator"
                            label="Aplica desconto" 
                            >
                            <AppSelect size="small" options={[ { value: true, label: "Sim" }, { value: false, label: "Não" }]}></AppSelect>
                        </AppFormItem>  
                        <AppFormItem
                            span={8}
                            name="status"
                            label="Status" 
                            >
                            <AppSelect disabled={! currentRecord} size="small" options={[ { value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }]}></AppSelect>
                        </AppFormItem>         
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default CategoryModalForm;