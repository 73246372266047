import { http } from '.';

const JobService = {
    get : (filter, page, size) => http.get(`job?search=${filter.search}&status=${filter.status}&page=${page}&pageSize=${size}`),
    create: (model) => http.post('job', model),
    update: (id, model) => http.put(`job/${id}`, model),
}

export default JobService;

