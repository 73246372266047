import { http } from '.';

const ColaboratorService = {
    get : (filter, page, size) => http.get(`colaborator?search=${filter.search}&status=${filter.status}&page=${page}&pageSize=${size}`),    
    getPPCS : (filter, page, size) => http.get(`colaborator/ppc?search=${filter.search}&status=${filter.status}&page=${page}&pageSize=${size}`),    
    create: (model) => http.post('colaborator', model),
    update: (id, model) => http.put(`colaborator/${id}`, model),
};

export default ColaboratorService;

